function addEventListenerClickCheckCodition() {
	$("#terms-n-condition label").on("click", function (e) {
		var _input = this.getElementsByTagName("input")[0];
		var termCont = document.getElementById("termCont");
		var _lis = termCont.getElementsByTagName("label");

		if (_input.getAttribute("id") == "checkAll") {
			if (_input.checked == true) {
				checkAll(_lis);
			} else {
				uncheckAll(_lis);
			}
		} else {
			toggleCheckAllInput(_lis);
		}
	});
}

function checkAll(_lis) {
	for (var i = 0; i < _lis.length; i++) {
		var _input = _lis[i].getElementsByTagName("input")[0];
		_input.checked = true;
	}
}

function uncheckAll(_lis) {
	for (var i = 0; i < _lis.length; i++) {
		var _input = _lis[i].getElementsByTagName("input")[0];
		_input.checked = false;
	}
}

function toggleCheckAllInput(_lis) {
	let count = 0;
	let totalConditions = 3;
	let _input;
	for (var i = 0; i < _lis.length; i++) {
		_input = _lis[i].getElementsByTagName("input")[0];
		if (_input.checked === true) {
			count++;
		}
	}

	var checkAll = document.getElementById("terms-n-condition");
	_input = checkAll.getElementsByTagName("input")[0];
	if (totalConditions !== count) {
		_input.checked = false;
	} else {
		_input.checked = true;
	}
}

function addEventListenerClickTabContent() {
	var _productContent = document.getElementById("product-content");

	var _tabsNav = document.getElementById("tabs-nav");
	var _tabsNavLis = _tabsNav.getElementsByTagName("li");
	var _tabContents = _productContent.getElementsByClassName("tab-content");

	$("#product-content #tabs-nav li").on("click", function (e) {
		e.preventDefault();
		if (this.classList.contains("active") == false) {
			for (var i = 0; i < _tabsNavLis.length; i++) {
				_tabsNavLis[i].classList.remove("active");
			}

			for (var i = 0; i < _tabContents.length; i++) {
				$(_tabContents[i]).slideUp(500);
			}

			this.classList.add("active");
			var _index = this.getAttribute("data-index");

			for (var i = 0; i < _tabContents.length; i++) {
				if (_tabContents[i].getAttribute("data-index") == _index) {
					$(_tabContents[i]).slideDown(500);
				}
			}
		}
	});
}

function addEventListenerClickButtonSearch() {
	$(".search-product .btn-search-open").on("click", function (e) {
		var search_product = this.closest(".search-product");
		search_product.classList.remove("closed");
		search_product.classList.add("opened");
		var _width = window.innerWidth;

		if (search_product.getAttribute("location") != "local") {
			var main_nav = document.querySelector("#main-nav");
			main_nav.style.opacity = "0";
			var logo = document.querySelector("#logo");

			if (_width <= 575) {
				logo.style.transform = "translateX(-30%)";
				logo.style.opacity = "0";
			}
		} else {
			if (_width <= 575) {
				var main_heading = this.closest(".main-heading");
				var title = main_heading.querySelector(".title");
				title.style.opacity = "0";
			}
		}
	});

	$(".search-product .btn-search-close").on("click", function (e) {
		var search_product = this.closest(".search-product");
		search_product.classList.remove("opened");
		search_product.classList.add("closed");
		var _width = window.innerWidth;

		if (search_product.getAttribute("location") != "local") {
			var main_nav = document.querySelector("#main-nav");
			main_nav.style.opacity = "1";
			var logo = document.querySelector("#logo");

			if (_width <= 575) {
				logo.style.transform = "translateX(0)";
				logo.style.opacity = "1";
			}
		} else {
			if (_width <= 575) {
				var main_heading = this.closest(".main-heading");
				var title = main_heading.querySelector(".title");
				title.style.opacity = "1";
			}
		}
	});
}

function clickBtnSearchClose() {
	$(".search-product .btn-search-close").click();
}

function addEventListenerClickBusinessInfo() {
	var regis_form = document.querySelector(".regis-form");

	var _tabsNav = regis_form.querySelector(".tabs-nav");
	var _tabsNavLis = _tabsNav.getElementsByTagName("li");
	var _tabContents = regis_form.getElementsByClassName("tab-content");

	$(".regis-form .tabs-nav li").on("click", function (e) {
		e.preventDefault();
		if (this.classList.contains("active") == false) {
			for (var i = 0; i < _tabsNavLis.length; i++) {
				_tabsNavLis[i].classList.remove("active");
			}

			for (var i = 0; i < _tabContents.length; i++) {
				$(_tabContents[i]).slideUp(500);
			}

			this.classList.add("active");
			var _index = this.getAttribute("data-index");

			for (var i = 0; i < _tabContents.length; i++) {
				if (_tabContents[i].getAttribute("data-tab") == _index) {
					$(_tabContents[i]).slideDown(500);
				}
			}
		}
	});
}

function addEventListenerClickADOCondition() {
	$(".w-checkbox label").on("click", function (e) {
		e.preventDefault();
		var checkbox = this.closest(".w-checkbox");
		var input = checkbox.getElementsByTagName("input")[0];

		if (input.getAttribute("id") === "agreementCheckAll") {
			if (input.checked) {
				uncheckAllAgreementADO();
			} else {
				checkAllAgreementADO();
			}
		} else {
			if (input.checked === true) {
				input.checked = false;
				checkbox.classList.remove("checked");
			} else {
				input.checked = true;
				checkbox.classList.add("checked");
			}
			toggleCheckAllADO();
		}
	});
}

function checkAllAgreementADO() {
	const checkboxs = $(".w-checkbox");
	for (const checkbox of checkboxs) {
		const input = checkbox.getElementsByTagName("input")[0];
		input.checked = true;
		checkbox.classList.add("checked");
	}
}

function uncheckAllAgreementADO() {
	const checkboxs = $(".w-checkbox");
	for (const checkbox of checkboxs) {
		const input = checkbox.getElementsByTagName("input")[0];
		input.checked = false;
		checkbox.classList.remove("checked");
	}
}

function toggleCheckAllADO() {
	let count = 0;
	let totalConditions = 3;
	let checkAllIndex = 0;
	let _input;
	let _checkboxs = $(".w-checkbox");

	for (let i = 0; i < _checkboxs.length; i++) {
		const checkbox = _checkboxs[i];
		_input = checkbox.getElementsByTagName("input")[0];

		if (_input.getAttribute("id") === "agreementCheckAll") {
			checkAllIndex = i;
		} else if (_input.checked === true) {
			count++;
		}
	}

	const _checkbox = _checkboxs[checkAllIndex];
	_input = _checkbox.getElementsByTagName("input")[0];
	if (totalConditions !== count) {
		_input.checked = false;
		_checkbox.classList.remove("checked");
	} else {
		_input.checked = true;
		_checkbox.classList.add("checked");
	}
}

function mainSwiper() {
	new Swiper(".main-swiper", {
		loop: true,
		pagination: {
			el: ".swiper-pagination",
			type: "custom",
			renderCustom: function (swiper, current, total) {
				return (
					'<span class="swiper-pagination-current">' +
					(current > 9 ? current : current) +
					"</span>/" +
					(total > 9 ? total : total)
				);
			},
			clickable: true,
		},
		navigation: {
			nextEl: ".swiper-next",
			prevEl: ".swiper-prev",
		},
		scrollbar: {
			enabled: false,
		},
	});
}

// SWIPER SLIDER
// const swiper = new Swiper(".mainSlider", {
// 	// configure Swiper to use modules
// 	modules: [Navigation, Pagination],
// });
// END SWIPER SLIDER

// function getInstanceModal(id) {
// 	return bootstrap.Modal.getInstance(document.querySelector(id));
// }

// function initModal(id) {
// 	return new bootstrap.Modal(document.querySelector(id));
// }

// function isShowBootstrap(id) {
// 	let modal = bootstrap.Modal.getInstance(document.querySelector(id));
// 	if(modal !== null && modal !== undefined) {
// 		console.log(modal._isShown)
// 	}

// 	return false;
// }

// function openOffcanvas(id) {
// 	let myOffcanvas = document.getElementById(id);
// 	let bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas, {backdrop: true, ignoreBackdropClick: false});
// 	console.log(bsOffcanvas)
// 	bsOffcanvas.show();
// }

// function closeOffcanvas(id) {
// 	let myOffcanvas = document.getElementById(id);
// 	let bsOffcanvas = bootstrap.Offcanvas.getInstance(myOffcanvas);
// 	console.log(bsOffcanvas)
// 	bsOffcanvas.hide();
// }

const dropdownElement = document.querySelectorAll(".dropdown-toggle");
const dropdownList = [...dropdownElement].map(
	(dropdownToggleEl) => new bootstrap.Dropdown(dropdownToggleEl)
);

// const offcanvasElementList = [].slice.call(document.querySelectorAll('.offcanvas'))
// const offcanvasList = offcanvasElementList.map(function (offcanvasEl) {
//   return new bootstrap.Offcanvas(offcanvasEl, {backdrop: true})
// })

// const myOffcanvas = document.getElementById(id)
// myOffcanvas.addEventListener('hidden.bs.offcanvas', event => {
// })
